@import "../../variables";

.add-blog {
  display: grid;

  &__header-wrapper {
    font-size: $header-medium;
    letter-spacing: $letter-spacing-mid;
    text-transform: uppercase;
    color: $color1-mid;

    margin-bottom: $margin-small;
  }

  &__content-wrapper {
    margin: 0 auto;
    padding: $padding-mid $padding-smallest;

    width: 100%;
    max-width: 50rem;
  }

  &__form-wrapper {
    margin-bottom: $margin-small;
  }

  &__button-wrapper {

  }
}