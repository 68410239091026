@import "./variables.scss";

$width-without-scrollbar: calc(100vw - #{$scroll-width});

@mixin no-select {
  -webkit-tap-highlight-color: transparent;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

@mixin disabled {
  cursor: default;
  background-color: $color-disabled;
}

@mixin link {
  color: $color-link;

  cursor: pointer;
  transition: all 0.3s;

  @media (hover: hover) {
    &:hover {
      text-decoration: underline;
    }
  }

  &:visited {
    color: $color-link
  }

  @include no-select
}

@mixin image-sizing {
  min-width: 100%;
  width: auto;
  height: 100%;

  object-fit: cover;
}

@mixin center-x-absolute {
  left: 50%;

  margin-right: -50%;

  transform: translateX(-50%);
}

@mixin center-absolute {
  top:50%;
  left: 50%;

  margin-right: -50%;

  transform: translate(-50%, -50%);
}

@mixin style-scrollbar {
  &::-webkit-scrollbar {
    width: $scroll-width;
    height: 0;

    @media only screen and (max-width: 600px) {
      background: none;
    }
  }

  &::-webkit-scrollbar-track {
    background: $color2-light;
  }

  &::-webkit-scrollbar-thumb {
    background: rgba($color2-mid, 5%);
  }
}

@mixin hide-scrollbar {
  &::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

@mixin initial-image-container {
  position: relative;

  height: calc(100vh - #{$header-height});
  max-height: 70rem;

  width: $width-without-scrollbar;

  @media (max-width: $breakpoint-phone) {
    max-height: 40rem;
  }
}