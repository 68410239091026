@import "../../variables";
@import "../../mixins";

.textarea {
  font-family: inherit;
  font-size: $text-small;

  border: 1px solid $border-color-dark;

  padding: 1rem;

  height: 9rem;
  width: 100%;

  resize: none;
  overflow: auto;
  box-shadow: 0 2px 10px $color2-light;

  &:focus {
    outline: none;
    box-shadow: 1px 1px 10px $color2-mid;
  }

  @include style-scrollbar;
}