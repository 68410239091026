@import "../../variables";
@import "../../mixins";

.modal {
  &__background {
    position: fixed;
    top: 0;
    left: 0;

    height: 100vh;
    width: 100vw;

    z-index: $level2 +10 ;
    background-color: rgba($color2-lightest, 0.5);
    backdrop-filter: blur(5px);
  }

  &__content {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    position: fixed;

    max-height: 50rem;
    width: fit-content;
    min-width: 40rem;
    max-width: 55rem;

    overflow-y: scroll;
    z-index: $level2 +11 ;
    background-color: $color2-lightest;
    box-shadow: 0 3px 20px $color2-mid;

    -webkit-overflow-scrolling: touch;

    @include center-absolute;
    @include style-scrollbar;

    @media (max-width: $breakpoint-phone) {
      height: 100vh;
      width: 100vw;
      max-height: initial;
      min-width: initial;
      max-width: initial;
    }
  }

  &__header {
    display: flex;
    justify-content: flex-end;
    flex-basis: 100%;

    padding: $padding-small $padding-smallest;
  }

  &__close {
    font-size: $text-medium;
    color: $color2-dark;

    cursor: pointer;
  }

  &__children {
    flex-basis: 100%;

    padding: $padding-mid $padding-small;
  }
}