@import "./variables.scss";

@keyframes in-and-out-animation {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes slide-in-from-top {
  from {
    transform: translateY(-100vh);
  }
  to {
    transform: translateY(0);
  }
}

@keyframes slide-in-from-parent {
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0);
  }
}