@import "../../variables";

.arrow-button {
  padding: $padding-smallest;
  border: 1px solid $color2-dark;

  cursor: pointer;
  border-radius: $circle-radius;
  background: rgba($color2-lightest, 1);
  transition: all $time-less;

  height: 5rem;
  width: 5rem;

  &:focus {
    outline: none;
  }

  &:disabled {
    cursor: default;
    background-color: $color-disabled;
  }

  @media (hover: hover) {
    &:hover {
      box-shadow: 0 2px 10px $border-color-dark;
    }
  }

  &:active {
    box-shadow: inset 0 2px 10px $border-color-dark;
  }
}