@import "../../variables";

.contact {
  &__header {
    padding: $padding-smallest $padding-small;
    font-size: $header-medium;
    letter-spacing: $letter-spacing-mid;
    color: $color1-mid;
    text-transform: uppercase;
  }

  &__content-container {

  }

  &__content {
    display: flex;
    flex-wrap: wrap;

    padding: $padding-small $padding-smallest;
    border-bottom: 1px solid $color2-mid;

    &:not(:last-child) {
      margin-bottom: $margin-small;
    }
  }

  &__content-top {
    display: flex;
    justify-content: space-between;
    flex-basis: 100%;

    margin-bottom: $margin-small;
  }

  &__content-client-container{

  }

  &__content-client-data {
    display: flex;
    justify-content: flex-start;
  }

  &__content-client-data-label {
    font-size: $text-medium;
    margin-right: $margin-smallest;
  }

  &__content-client-data-value {
    font-size: $text-medium;
    font-weight: bold;
  }

  &__content-date {
    font-size: $text-medium;
  }

  &__content-bottom {
    flex-basis: 100%;
  }

  &__content-subject {
    display: flex;
    font-size: $text-large;
  }

  &__content-content {
    display: flex;
    font-size: $text-medium;
  }

  &__content-label {
    font-weight: bold;
    margin-right: $margin-smallest;
  }
}