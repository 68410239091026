@import "../../variables";
@import "../../mixins";

.login {
  min-height: 100vh;
  width: 100%;

  display: grid;

  background-color: $color2-light;

  &__content-wrapper {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    margin: auto;
    border: 1px solid $color2-mid;
    padding: $padding-mid $padding-large;

    width: 100%;
    max-width: 50rem;

    background-color: #ffffff;
    box-shadow: 5px 5px 30px $color2-mid;
    border-radius: $edgeless-radius;

    @media (max-width: $breakpoint-phone) {
      padding: $padding-mid $padding-smallest;
    }
  }

  &__form-wrapper {
    flex-basis: 100%;
    max-width: 40rem;
  }

  &__button-wrapper {
    text-align: center;

    margin-top: $margin-small;

    flex-basis: 100%;
  }

  &__logo-wrapper {
    margin-bottom: $margin-smallest;
  }

  &__logo {

  }
}