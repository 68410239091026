@import "../../variables";

.form {
  transition: height $time-less;
  height: fit-content;

  &__item {
    display: flex;
    flex-wrap: wrap;

    &:not(:last-child) {
      margin-bottom: $margin-smallest;
    }
  }

  &__item-label {
    flex-basis: 100%;
    font-size: $text-medium;
    letter-spacing: $letter-spacing-small;
  }

  &__alerted {
    border: 1px solid $color-alert !important;
  }

  &__message-wrapper {
    overflow: hidden;
  }

  &__message {
    font-size: $text-small;
    color: $color-alert;

    z-index: -1;
    animation: slide-in-from-parent $time-less;
  }
}