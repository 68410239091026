// for browser default
$font-size: 62.5%; // calculated by 10px / 16px. 1rem = 10px.

/*
 *
 */
$general-font: "Poppins", sans-serif;
$header-font: "Lora", serif;

/*
 * FONT SIZES
 */
$text-default: 1rem;
$text-small: 1.2rem;
$text-medium: 1.4rem;
$text-large: 2rem;
$header-small: 2.8rem;
$header-medium: 4rem;
$header-large: 5rem;

/*
 * COLORS
 */
$color1-dark: #012169;
$color1-mid: #052E44;
$color1-light: #2D55B3;
$color1-lightest: #86A5D9;

$color2-dark: #7A8F90;
$color2-mid: #e8e8e8;
$color2-light: #f4f4f4;
$color2-lightest: #ffffff;

$color3-dark: #f50000;
$color3-mid: #ff3131;
$color3-light: #ef8181;
$color3-lightest: #eac4c4;

$text-color: #1c1c1c;
$border-color-light: $color2-light;
$border-color-dark: $color2-mid;

$color-disabled: $color2-dark;
$color-link: $color3-mid;
$color-alert: #E61005;

$loader-background: rgba($color2-lightest, 0.8);

/*
 * BREAKPOINTS
 */
$breakpoint-phone: 35em; // 560
$breakpoint-tablet: 48em; // 768
$breakpoint-desktop: 64em; // 1024

/* 
 * CONSTANTS
 */
$header-height: 9rem;
$scroll-width: 0.5rem;
$sidebar-width: 25rem;
$input-height: 4rem;

/*
 * CSS CONSTANTS
 */
// margin
$margin-smallest: 1rem;
$margin-small: 2rem;
$margin-mid: 3rem;
$margin-large: 5rem;
$margin-largest: 8rem;
$margin-huge: 13rem;

//padding
$padding-smallest: 1rem;
$padding-small: 2rem;
$padding-mid: 3rem;
$padding-large: 5rem;
$padding-largest: 8rem;
$padding-huge: 13rem;

// border radius
$circle-radius: 50%;
$round-radius: 3rem;
$smooth-radius: 1.5rem;
$edgeless-radius: 0.5rem;

//z index
$level1: 9999;
$level2: 5555;
$level3: 1000;
$level4: 100;
$level5: 1;

//time constants
$time-little: 0.3s;
$time-less: 0.5s;
$time-normal: 0.8s;
$time-long: 1.5s;

//letter spacing
$letter-spacing-small: 0.1rem;
$letter-spacing-mid: 0.2rem;
$letter-spacing-large: 0.3rem;