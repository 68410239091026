@import "../../variables";

.footer {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  padding: $padding-largest $padding-large;

  background: $color2-mid;
  box-shadow: inset 0 5px 20px $color2-dark;

  @media (max-width: $breakpoint-phone) {
    padding: $padding-largest $padding-smallest;
  }

  &__social-media {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-basis: 60%;

    width: auto;

    @media (max-width: $breakpoint-desktop) {
      flex-basis: 100%;
      flex-wrap: wrap;
      justify-content: center;

      margin-bottom: $margin-largest;
    }

    &__logo-wrapper {
      width: fit-content;
      height: 9rem;

      padding: $padding-smallest 0;
      margin-right: $margin-mid;

      border-right: 1px solid $color1-dark;

      @media (max-width: $breakpoint-phone) {
        width: fit-content;

        border-right: none;
        border-top: 1px solid $color1-dark;

        padding: $padding-small 0;
        margin-right: 0;
      }
    }

    &__logo {
      @media (max-width: $breakpoint-desktop) {
        width: 18rem;
      }

      height: 100%;
    }

    &__right-content {
      @media (max-width: $breakpoint-phone) {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        flex-basis: 100%;
      }
    }

    &__text {
      font-size: $header-small;
      color: $color2-dark;

      @media (max-width: $breakpoint-phone) {
        flex-basis: 100%;
        text-align: center;

        margin-bottom: $margin-small;
      }
    }

    &__icons {
      display: flex;
      justify-content: space-between;

      width: 100%;

      @media (max-width: $breakpoint-phone) {
        max-width: 25rem;
        margin-bottom: $margin-mid;
      }
    }

    &__icon-wrapper {
      font-size: $text-large;

      text-decoration: none;

      &:not(:last-child) {
        margin-right: $margin-smallest;
      }
    }

    &__icon {
      color: $color2-dark;

      transition: all $time-less;

      @media (hover: hover) {
        &:hover {
          color: $color1-dark;
        }
      }
    }
  }

  &__actions {
    display: flex;
    justify-content: center;
    flex-basis: 40%;

    padding: 0 $padding-smallest;

    @media (max-width: $breakpoint-desktop) {
      flex-basis: 100%;
    }

    &__menu-wrapper {
      display: flex;
      justify-content: space-between;
      width: 100%;
      max-width: 50rem;

      @media (max-width: $breakpoint-desktop) {
        flex-wrap: wrap;
      }
    }

    &__menu-item {
      display: flex;
      justify-content: center;
      align-items: center;

      font-size: $text-medium;
      color: $color2-dark;

      cursor: pointer;
      transition: all $time-less;

      @include no-select;

      @media (hover: hover) {
        &:hover {
          color: $color1-dark;
        }
      }

      @media (max-width: $breakpoint-desktop) {
        flex-basis: 100%;
      }

      &:not(:last-child) {
        @media (max-width: $breakpoint-desktop) {
          margin-bottom: $margin-small;
        }
      }
    }
  }
}