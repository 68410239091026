@import "./style/variables";
@import "./style/mixins";

*,
*::before,
*::after {
  box-sizing: inherit;
  margin: 0;
  padding: 0;
}

html {
  box-sizing: border-box;
  font-size: $font-size;
}

body {
  font-family: 'Poppins', sans-serif;
  font-size: inherit;
  color: $text-color;


  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  @include style-scrollbar;
}

#root {
  width: 100%;
  height: 100%;
}
