@import "../../variables.scss";

.layout {
  &__header-container {
    width: 100%;
    height: $header-height;
  }

  &__content-container {
    width: 100%;
    min-height: calc(100vh - #{$header-height});
  }

  &__footer-container {
    height: fit-content;
    width: 100%;
  }
}