@import "../../variables";

.quill-editor {
  width: 100%;
  font-size: $text-small;
}

/* QUILL'S BUILD IN CLASSES */

.ql-toolbar.ql-snow .ql-formats {
  margin-right: 10px;
}

.ql-snow .ql-picker {
  height: unset;
}

.ql-size-small {
  font-size: $text-default;
}
.ql-size-large {
  font-size: $text-medium;
}
.ql-size-huge {
  font-size: $text-large;
}

.ql-align-center {
  text-align: center;
}

.ql-align-justify {
  text-align: justify;
}

.ql-align-right {
  text-align: right;
}

ol li.ql-indent-1 {
  counter-increment: list-1;
}
ol li.ql-indent-1:before {
  content: counter(list-1, lower-alpha) '. ';
}
ol li.ql-indent-1 {
  counter-reset: list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
}
ol li.ql-indent-2 {
  counter-increment: list-2;
}
ol li.ql-indent-2:before {
  content: counter(list-2, lower-roman) '. ';
}
ol li.ql-indent-2 {
  counter-reset: list-3 list-4 list-5 list-6 list-7 list-8 list-9;
}
ol li.ql-indent-3 {
  counter-increment: list-3;
}
ol li.ql-indent-3:before {
  content: counter(list-3, decimal) '. ';
}
ol li.ql-indent-3 {
  counter-reset: list-4 list-5 list-6 list-7 list-8 list-9;
}
ol li.ql-indent-4 {
  counter-increment: list-4;
}
ol li.ql-indent-4:before {
  content: counter(list-4, lower-alpha) '. ';
}
ol li.ql-indent-4 {
  counter-reset: list-5 list-6 list-7 list-8 list-9;
}
ol li.ql-indent-5 {
  counter-increment: list-5;
}
ol li.ql-indent-5:before {
  content: counter(list-5, lower-roman) '. ';
}
ol li.ql-indent-5 {
  counter-reset: list-6 list-7 list-8 list-9;
}
ol li.ql-indent-6 {
  counter-increment: list-6;
}
ol li.ql-indent-6:before {
  content: counter(list-6, decimal) '. ';
}
ol li.ql-indent-6 {
  counter-reset: list-7 list-8 list-9;
}
ol li.ql-indent-7 {
  counter-increment: list-7;
}
ol li.ql-indent-7:before {
  content: counter(list-7, lower-alpha) '. ';
}
ol li.ql-indent-7 {
  counter-reset: list-8 list-9;
}
ol li.ql-indent-8 {
  counter-increment: list-8;
}
ol li.ql-indent-8:before {
  content: counter(list-8, lower-roman) '. ';
}
ol li.ql-indent-8 {
  counter-reset: list-9;
}
ol li.ql-indent-9 {
  counter-increment: list-9;
}
ol li.ql-indent-9:before {
  content: counter(list-9, decimal) '. ';
}
li::before {
  display: inline-block;
  white-space: nowrap;
  width: 1.2em;
}
li:not(.ql-direction-rtl)::before {
  margin-left: -1.5em;
  margin-right: 0.3em;
  text-align: right;
}
li.ql-direction-rtl::before {
  margin-left: 0.3em;
  margin-right: -1.5em;
}
ol li:not(.ql-direction-rtl),
ul li:not(.ql-direction-rtl) {
  padding-left: 1.5em;
}
ol li.ql-direction-rtl,
ul li.ql-direction-rtl {
  padding-right: 1.5em;
}
ol li {
  counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
  counter-increment: list-0;
}
ol li:before {
  content: counter(list-0, decimal) '. ';
}