@import "../../variables";
@import "../../mixins";

@mixin message {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  flex-basis: 100%;

  font-size: $text-medium;
  text-align: start;

  padding: $padding-small $padding-smallest;
  word-break: break-word;

  width: 40rem;
  height: auto;

  z-index: $level1 + 10000;
  background-color: $color2-lightest;
  animation: message-animation 0.3s;

  @media (max-width: $breakpoint-phone) {
    width: 100%;
    padding: $padding-smallest;
  }

  &:not(:last-child) {
    margin-bottom: $margin-small;

    @media (max-width: $breakpoint-phone) {
      margin-bottom: $margin-smallest;
    }
  }

  &:nth-child(1) {
    margin-top: $margin-mid;

    @media (max-width: $breakpoint-phone) {
      margin-top: $margin-smallest;
    }
  }
}

.message {
  &__success {
    @include message;
    border: 1px solid $color1-mid;
  }

  &__error {
    @include message;
    border: 1px solid $color3-dark;
  }

  &__check {
    font-size: $header-small;
    color: $color1-mid;
  }

  &__cancel {
    font-size: $header-small;
    color: $color3-dark;
  }

  &__content-wrapper {
    display: flex;
    flex-wrap: wrap;
    flex-basis: 100%;

    @include no-select
  }

  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;

    max-width: 8rem;
    flex-basis: 30%;
  }

  &__content {
    display: flex;
    justify-content: center;
    align-items: center;

    width: auto;
    flex-basis: 70%;
  }

  &__gate {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 100%;

    &__messages {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;

      position: fixed;
      bottom: 2rem;

      width: 40rem;

      z-index: $level2;
      transition: all 1s;

      @media (max-width: $breakpoint-phone) {
        width: 100%;
        height: fit-content;
        top: 0;

        padding: $padding-smallest;
      }
    }
  }

  &__confirmation-container {
    display: flex;
    justify-content: center;
    align-items: center;

    position: fixed;
    top: 0;
    left: 0;

    width: 100vw;
    height: 100vh;

    background-color: $loader-background;
    z-index: $level2;

    @media (max-width: $breakpoint-phone) {
      align-items: flex-start;
    }
  }

  &__confirmation-wrapper {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    background-color: $color2-lightest;

    border: 1px solid $color2-mid;
    padding: $padding-small;

    width: 50rem;

    animation: confirmation-animation 0.3s;

    @media (max-width: $breakpoint-phone) {
      width: calc(100% - 2rem);
      height: fit-content;

      margin: $margin-small $margin-smallest 0;

      animation: confirmation-animation-mobile 0.3s;
    }
  }

  &__text-wrapper {
    font-size: $text-medium;
    color: $text-color;

    margin-bottom: $margin-small;
    padding: $padding-small;
  }

  &__button-container {
    display: flex;
    justify-content: flex-end;
    flex-basis: 100%;
  }

  &__button {
    min-width: 8rem;

    &:not(:last-child) {
      margin-right: $margin-smallest;
    }
  }

  &__action-button {
    background-color: $color3-dark !important;
  }
}

@keyframes message-animation {
  from {
    transform: translate3d(0, 100%, 0);
    height: 0;
  }
  to {
    transform: translate3d(0, 0, 2rem);
    height: 100%;
  }
}

@keyframes confirmation-animation {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes confirmation-animation-mobile {
  from {
    transform: translate3d(0, 100%, 0);
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}
