@import "../../variables";
@import "../../mixins";

.select {
  position: relative;

  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

  font-size: $text-medium;

  padding: 0 $padding-small;
  border: 1px solid $color2-mid;

  width: 100%;
  height: $input-height;

  cursor: pointer;

  @include no-select();

  &__outer-wrapper {
    width: 100%;
    height: $input-height;
  }

  &__options-container {
    position: relative;

    flex-basis: 100%;
  }

  &__options-wrapper {
    position: absolute;
    top: $padding-smallest;

    display: flex;
    flex-wrap: wrap;

    width: 100%;

    cursor: pointer;
    z-index: $level4;

    @include no-select();
  }

  &__option {
    flex-basis: 100%;

    text-align: center;

    padding: $padding-smallest $padding-small;
    border-bottom: 1px solid $color2-mid;

    background-color: $color2-lightest;
  }
}