@import "../../variables";
@import "../../mixins";

.landing-page {
  display: grid;

  &__content-wrapper {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    margin: auto;
    padding: $padding-small $padding-smallest;
  }

  &__options-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    flex-basis: 100%;
  }

  &__option-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    flex-basis: 33.3%;

    color: $color2-dark;

    padding: $padding-small $padding-mid;

    transition: all $time-less;
    cursor: pointer;

    @include no-select;

    &:not(:last-child) {
      border-right: 1px solid $color2-mid;

      @media (max-width: $breakpoint-tablet) {
        border-right: none;
      }
    }

    @media (hover: hover) {
      &:hover {
        color: $color3-light;
      }
    }

    @media (max-width: $breakpoint-tablet) {
      flex-basis: 100%;
      max-width: 35rem;
      border-bottom: 1px solid $color2-mid;
    }
  }

  &__option-icon {
    font-size: $header-large;
    margin-bottom: $margin-small;
  }

  &__option-text {
    flex-basis: 100%;

    font-size: $text-large;
    text-transform: uppercase;
    letter-spacing: $letter-spacing-small;
    text-align: center;
  }

  &__logout-wrapper {
    display: flex;
    align-items: center;

    color: $color3-mid;
    font-size: $text-large;

    padding: $padding-smallest;

    transition: all $time-less;
    cursor: pointer;

    @include no-select;

    @media (hover: hover) {
      &:hover {
        color: $color3-dark;
      }
    }
  }

  &__logout-icon {
    margin-right: $margin-small;

  }

  &__logout-text {

  }
}