@import "../../variables";
@import "../../keyframes";

.loader-wrapper {
  width: 100%;
  height: 100%;
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;

  position: fixed;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  z-index: $level1;
  background-color: rgba($loader-background, 0.5);
  backdrop-filter: blur(5px);
}

.loader-spinner {
  animation: in-and-out-animation $time-long infinite;
  box-shadow: 0 3px 20px $color2-dark;
}