@import "../../variables";
@import "../../keyframes";
@import "../../mixins";

.header-container {
  position: relative;

  width: 100%;
  height: $header-height;
  z-index: 0;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  position: absolute;
  left: 0;
  right: 0;

  padding: $padding-smallest $padding-small;
  border-bottom: 1px solid $border-color-dark;

  width: 100%;
  height: $header-height;

  z-index: $level2 + 5;
  background-color: $color2-lightest;

  @include no-select;

  &__logo-container {
    height: 6rem;
    width: 18rem;
  }

  &__logo {
    cursor: pointer;

    height: 100%;
    width: 100%;

    @media (max-width: $breakpoint-tablet) {
      width: 15rem;
    }

    @media (max-width: $breakpoint-phone) {
      width: 8rem;
    }
  }

  &__menu {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-wrap: wrap;

    width: fit-content;
    height: 100%;

    @media (max-width: $breakpoint-phone) {
      flex-wrap: wrap;
      width: 100%;

      z-index: $level2 + 4;
      background-color: $color2-lightest;

      animation: slide-in-from-top $time-normal;
    }

    &__language-selection {
      display: flex;
      justify-content: flex-end;
      align-items: flex-start;

      flex-basis: 100%;

      margin-bottom: $margin-mid;

      @media (max-width: $breakpoint-phone) {
        flex-wrap: wrap;

        margin-bottom: 0;
      }
    }

    &__language {
      letter-spacing: $letter-spacing-small;
      font-weight: bold;
      text-transform: uppercase;

      cursor: pointer;

      &:not(:last-child) {
        margin-right: $margin-large;

        @media (max-width: $breakpoint-tablet) {
          margin-right: $margin-mid;
        }

        @media (max-width: $breakpoint-phone) {
          margin-right: 0;
        }
      }

      @media (hover: hover) {
        &:hover {
          color: $color1-lightest;

          @media (max-width: $breakpoint-phone) {
            background-color: $color2-light;;
          }
        }
      }

      @media (max-width: $breakpoint-phone) {
        text-align: center;
        flex-basis: 50%;
        border-bottom: 1px solid $border-color-light;

        line-height: $text-large;

        padding: $padding-smallest 0;
      }

      &__selected {
        color: $color1-lightest;
        border-bottom: 1px solid $color1-lightest;
      }
    }

    &__option {
      font-size: $text-small;
      letter-spacing: $letter-spacing-small;
      font-weight: bold;

      cursor: pointer;
      transition: all $time-little;

      &:not(:last-child) {
        margin-right: $margin-large;

        @media (max-width: $breakpoint-tablet) {
          margin-right: $margin-mid;
        }

        @media (max-width: $breakpoint-phone) {
          margin-right: 0;
          border-bottom: 1px solid $border-color-light;
        }
      }

      &:last-child {
        @media (max-width: $breakpoint-phone) {
          border-bottom: 2px solid $border-color-dark;
        }
      }

      @media (hover: hover) {
        &:hover {
          color: $color1-lightest;

          @media (max-width: $breakpoint-phone) {
            background-color: $color2-light;;
          }
        }
      }

      @media (max-width: $breakpoint-tablet) {
        font-size: $text-small;
        letter-spacing: $letter-spacing-small;
      }

      @media (max-width: $breakpoint-phone) {
        font-size: $text-medium;
        text-align: center;
        font-weight: initial;
        font-family: $header-font;

        padding: $padding-smallest 0;

        flex-basis: 100%;
      }
    }
  }

  &__mobile-menu-switch {
    font-size: $text-small;
    letter-spacing: $letter-spacing-small;

    cursor: pointer;
    transition: all $time-little;

    @media (hover: hover) {
      &:hover {
        color: $color1-lightest;
      }
    }
  }

  &__mobile-menu-wrapper {
    position: absolute;
    top: 100%;
    right: 0;

    width: 100%;
    height: fit-content;

    @media (max-width: $breakpoint-phone) {
      z-index: $level2 + 3;
    }
  }

  &__logout {
    color: $color3-dark !important;
  }
}