@import "../../mixins";

.spinner-container {
  position: relative;

  height: 6rem;
  width: auto;
}

.spinner {
  position: absolute;
  top: 0;

  height: 100%;
  width: auto;

  @include center-x-absolute;
}