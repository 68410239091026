@import "../../variables";

.arrow {
  position: relative;

  border: solid $color2-dark;
  border-width: 0 1px 1px 0;
  display: inline-block;

  transition: all $time-less;

  &__left {
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
  }

  &__right {
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
  }

  &__up {
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
  }

  &__down {
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
  }
}