@import "../../variables";
@import "../../mixins";

.button {
  text-transform: uppercase;
  letter-spacing: $letter-spacing-small;
  color: $color2-lightest;

  padding: $padding-small $padding-mid;
  border: 1px solid $color1-light;

  cursor: pointer;
  background: rgba($color1-mid, 1);
  transition: all $time-less;

  @include no-select;

  &:focus {
    outline: none;
  }

  &:disabled {
    cursor: default;
    background-color: $color-disabled;
  }

  @media (hover: hover) {
    &:hover {
      background: rgba($color1-dark, 1);
    }
  }

  &:active {
    background: rgba($color1-mid, 1);
  }
}