@import "../../variables";
@import "../../mixins";

$content-height: 25rem;
$image-width: 20rem;

.blog {
  &__header {
    display: flex;
    justify-content: space-between;

    width: 100%;
    height: fit-content;

    padding: $padding-smallest $padding-mid;

    @media (max-width: $breakpoint-phone) {
      padding: $padding-smallest;
    }
  }

  &__header-text {
    font-size: $header-medium;
    color: $color1-mid;
    letter-spacing: $letter-spacing-mid;
    text-transform: uppercase;

    @media (max-width: $breakpoint-phone) {
      font-size: $header-small;
    }
  }

  &__header-button {

  }

  &__content-wrapper {

  }

  &__content {
    display: flex;
    flex-wrap: wrap;

    height: $content-height;
    width: 100%;

    cursor: pointer;
    transition: all $time-less;

    @include no-select();

    &:not(:last-child) {
      margin-bottom: $margin-mid;
      padding-bottom: $padding-small;
      border-bottom: 1px solid $color2-mid;
    }

    @media (hover: hover) {
      &:hover {
        background-color: $color2-mid;
      }
    }
  }

  &__content-left {
    position: relative;

    flex-basis: $image-width;

    height: 100%;
    width: 20rem;

    overflow: hidden;
  }

  &__content-image {
    position: absolute;

    @include center-absolute();
    @include image-sizing();
  }

  &__content-right {
    display: flex;
    flex-wrap: wrap;
    flex-basis: calc(100% - #{$image-width});

    padding: $padding-smallest;

    height: 3rem;

    @media (max-width: $breakpoint-tablet) {
      flex-basis: 100%;
    }
  }

  &__content-top {
    display: flex;
    justify-content: space-between;
    flex-basis: 100%;

    margin-bottom: $margin-small;
  }

  &__content-locale {
    display: flex;
    align-items: flex-end;
  }

  &__content-locale-text {
    font-size: $text-medium;

    margin-right: $margin-smallest;
  }

  &__content-locale-value {
    font-size: $text-large;
    font-weight: bold;
    text-transform: uppercase;
  }

  &__content-creation {
    font-size: $text-medium;
  }

  &__content-bottom {
    flex-basis: 100%;
  }

  &__content-subject {
    font-size: $text-large;
    color: $color1-mid;

    margin-bottom: $margin-smallest;
    border-bottom: 1px solid $color1-mid;
  }

  &__content-content {
    font-size: $text-medium;
    overflow: hidden;

    text-overflow: clip;
    max-height: 10rem;

    overflow: hidden;
  }
}